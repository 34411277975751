import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import LoadingSecondaryButton from 'components/Buttons/LoadingSecondaryButton';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import api from 'api/app';

import {
  container,
  info,
  inputContainer,
  input,
  btn,
} from './ForgotPassword.module.scss';
import {
  errorMessage,
  successMessage,
} from 'styles/components/form.module.scss';

import Hero from './Hero/Hero';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmit = ({ email }) => {
    setError(false);
    setSuccess(false);
    setLoading(true);
    api
      .post('/auth/forgot-password', {
        email,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Hero title="ΑΝΑΚΤΗΣΗ ΚΩΔΙΚΟΥ">
      <div className={container}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <p className={info}>
            Συμπλήρωσε την διεύθυνση email που χρησιμοποίησες κατά την εγγραφή
            σου και θα αποσταλούν οδηγίες για την ανάκτηση του κωδικού σου.
          </p>
          <div className={inputContainer}>
            <input
              {...register('email', {
                required: 'Το πεδίο email εγγραφής είναι υποχρεωτικό',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Λάθος μορφή email',
                },
              })}
              type="email"
              placeholder="Email εγγραφής"
              aria-label="Email εγγραφής"
              className={input}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </div>
          <LoadingSecondaryButton
            buttonClass={btn}
            type="submit"
            loading={loading}
          >
            Ανάκτηση κωδικού
          </LoadingSecondaryButton>
        </form>
        {error && (
          <p className={errorMessage}>
            Η Ανάκτηση κωδικού δεν ολοκληρώθηκε. Προσπάθησε ξανά!
          </p>
        )}
        {success && (
          <p className={successMessage}>
            Η Ανάκτηση κωδικού ήταν επιτυχής. Σου έχει αποσταλεί σχετικό email.
          </p>
        )}
      </div>
    </Hero>
  );
};

export default ForgotPassword;
