import React from 'react';

import { title } from './Title.module.scss';
import { bannerHeader } from 'styles/components/typography.module.scss';

const Title = ({ children, isBanner = false, ...rest }) => {
  return (
    <h1 className={isBanner ? bannerHeader : title} {...rest}>
      {children}
    </h1>
  );
};

export default Title;
