import { Link } from 'gatsby';
import React, { useState } from 'react';

import ActionsContainer from 'components/ActionsContainer/ActionsContainer';
import AuthWidget from 'components/AuthWidget/AuthWidget';
import LoadingSecondaryButton from 'components/Buttons/LoadingSecondaryButton';
import Login from 'components/Login/Login';
import Register from 'components/Register/Register';

import { useAuthUser } from 'utilities/AuthUser';

import api from 'api/app';

import { registerContainer, title, btn, link } from './Account.module.scss';
import {
  errorMessage,
  successMessage,
} from 'styles/components/form.module.scss';

import Hero from './Hero/Hero';

const Account = () => {
  const { login: loginAuth, loading, isLoggedIn } = useAuthUser();
  const [loginError, setLoginError] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const register = ({ email, password }) => {
    setRegistrationError(false);
    setRegistrationSuccess(false);
    api
      .post('/auth/local/register', {
        email,
        password,
      })
      .then(() => {
        setRegistrationSuccess(true);
      })
      .catch(() => {
        setRegistrationError(true);
      });
  };

  const login = (data) => {
    setLoginError(false);
    loginAuth(data).catch(() => {
      setLoginError(true);
    });
  };

  return (
    <Hero title="ΣΥΝΔΕΣΗ/ΕΓΓΡΑΦΗ">
      <AuthWidget
        title={<h2 className={title}>Συνδεθείτε στο λογαριασμό σας</h2>}
        form={
          <Login
            onSubmit={login}
            actions={
              <>
                <ActionsContainer>
                  <LoadingSecondaryButton
                    type="submit"
                    buttonClass={btn}
                    loading={loading}
                    data-testid="login-btn"
                  >
                    ΣΥΝΔΕΣΗ
                  </LoadingSecondaryButton>
                </ActionsContainer>
                <Link to={'/app/forgot-password'} className={link}>
                  Ξέχασες το password σου;
                </Link>
              </>
            }
          />
        }
      >
        {loginError && !isLoggedIn && (
          <p className={errorMessage} data-testid="login-error-msg">
            Η σύνδεση δεν ολοκληρώθηκε. Προσπαθήστε ξανά!
          </p>
        )}
      </AuthWidget>
      <AuthWidget
        containerClass={registerContainer}
        title={<h2 className={title}>Εγγραφή Χρήστη</h2>}
        form={
          <Register
            onSubmit={register}
            actions={
              <ActionsContainer>
                <LoadingSecondaryButton
                  type="submit"
                  buttonClass={btn}
                  loading={loading}
                >
                  ΕΓΓΡΑΦΗ
                </LoadingSecondaryButton>
              </ActionsContainer>
            }
          />
        }
      >
        {registrationError && (
          <p className={errorMessage}>
            Η εγγραφή δεν ολοκληρώθηκε. Προσπαθήστε ξανά!
          </p>
        )}
        {registrationSuccess && (
          <p className={successMessage}>
            Η εγγραφή ολοκληρώθηκε. Σας έχει αποσταλεί email επιβεβαίωσης.
          </p>
        )}
      </AuthWidget>
    </Hero>
  );
};
export default Account;
