import { Link } from 'gatsby';
import React from 'react';

import Container from 'components/Container/Container';
import Hero from 'components/Hero/Hero';
import Layout from 'components/Layout';

import { useAuthUser } from 'utilities/AuthUser';

import {
  navContainer,
  nav,
  active,
  link,
  btn,
} from './ProfileContainer.module.scss';
import { linkInitialUnderlined } from 'styles/components/links.module.scss';
import { banner } from 'styles/pages/common.module.scss';

const noMargin = { margin: '0' };

const ProfileContainer = ({ children, title }) => {
  const { logout } = useAuthUser();

  return (
    <Layout fullWidth={true}>
      <Hero title={title} containerClass={banner} containerStyles={noMargin} />
      <div className={navContainer}>
        <Container withPadding={true}>
          <nav className={nav}>
            <Link
              activeClassName={active}
              className={`${linkInitialUnderlined} ${link}`}
              to="/app/profile"
            >
              Λογαριασμός
            </Link>
            <Link
              activeClassName={active}
              className={`${linkInitialUnderlined} ${link}`}
              to="/app/address"
            >
              Διευθύνσεις
            </Link>
            <Link
              activeClassName={active}
              className={`${linkInitialUnderlined} ${link}`}
              to="/app/business"
            >
              Για επιχειρήσεις
            </Link>
            <Link
              activeClassName={active}
              className={`${linkInitialUnderlined} ${link}`}
              to="/app/history"
            >
              Ιστορικό Παραγγελιών
            </Link>
            <button
              className={`${linkInitialUnderlined} ${btn}`}
              onClick={logout}
            >
              Αποσύνδεση
            </button>
          </nav>
        </Container>
      </div>
      <Container withPadding={true}>{children}</Container>
    </Layout>
  );
};

export default ProfileContainer;
