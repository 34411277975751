import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import {
  container,
  image,
  content,
  overlay,
  subtitle,
} from 'components/Hero/Hero.module.scss';
import Title from 'components/Title/Title';

const Hero = ({
  heroImage,
  title,
  description = null,
  containerClass = null,
  containerStyles = null,
}) => {
  return (
    <div
      style={containerStyles}
      className={`${container}${containerClass ? ' ' + containerClass : ''}`}
    >
      {heroImage ? (
        <GatsbyImage image={heroImage} className={image} alt="" />
      ) : (
        <StaticImage
          layout="fullWidth"
          quality={100}
          alt=""
          src="../../images/category.jpg"
          formats={['auto', 'webp', 'avif']}
          className={image}
        />
      )}
      <div className={`${content}${heroImage ? ' ' + overlay : ''}`}>
        <Title isBanner={true}>{title}</Title>
        {description ? <p className={subtitle}>{description}</p> : null}
      </div>
    </div>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  heroImage: PropTypes.object,
  fallbackImg: PropTypes.string,
};

export default Hero;
