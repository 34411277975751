import React from 'react';
import { useForm } from 'react-hook-form';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import { validateGreekPhone } from 'utilities/greekPhoneValidators';

import {
  profileForm,
  row,
  rowInner,
  inputContainer,
  label,
  input,
} from 'styles/components/form.module.scss';

const ProfileForm = ({ user, onSubmit, actions, containerClass = false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form
      className={`${profileForm}${containerClass ? ' ' + containerClass : ''}`}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <h4>Στοιχεία Επικοινωνίας</h4>
      <div className={row}>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="firstname">
            Όνομα
          </label>
          <input
            className={input}
            id="firstname"
            {...register('firstname', {
              required: 'Το πεδίο Όνομα είναι υποχρεωτικό',
            })}
            type="text"
            placeholder="Όνομα"
            aria-label="Όνομα"
            defaultValue={user?.firstname}
          />
          {errors.firstname && (
            <ErrorMessage>{errors.firstname.message}</ErrorMessage>
          )}
        </div>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="lastname">
            Επίθετο
          </label>
          <input
            className={input}
            id="lastname"
            {...register('lastname', {
              required: 'Το πεδίο Επίθετο είναι υποχρεωτικό',
            })}
            type="text"
            placeholder="Επίθετο"
            aria-label="Επίθετο"
            defaultValue={user?.lastname}
          />
          {errors.lastname && (
            <ErrorMessage>{errors.lastname.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className={row}>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="email">
            Email
          </label>
          <input
            className={input}
            id="email"
            {...register('email', {
              required: 'Το email είναι υποχρεωτικό',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Λάθος μορφή email',
              },
            })}
            type="email"
            placeholder="Email"
            aria-label="Email"
            defaultValue={user?.email}
          />
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </div>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="contactPhone">
            Τηλέφωνο
          </label>
          <input
            className={input}
            id="contactPhone"
            {...register('contactPhone', {
              required: 'Το πεδίο Τηλέφωνο επικοινωνίας είναι υποχρεωτικό',
              validate: (value) =>
                validateGreekPhone(value) ? true : 'Λάθος μορφή τηλεφώνου',
            })}
            type="text"
            placeholder="Κινητό ή σταθερό"
            aria-label="Τηλέφωνο επικοινωνίας"
            defaultValue={user?.contactPhone}
          />
          {errors.contactPhone && (
            <ErrorMessage>{errors.contactPhone.message}</ErrorMessage>
          )}
        </div>
      </div>
      {actions}
    </form>
  );
};

export default ProfileForm;
