import React from 'react';
import { VscLoading } from 'react-icons/vsc';

import SecondaryButton from 'components/Buttons/SecondaryButton';

const LoadingSecondaryButton = ({
  children,
  icon = null,
  loading,
  ...rest
}) => {
  return (
    <SecondaryButton disabled={loading} {...rest}>
      {loading ? <VscLoading className="spin" /> : icon} {children}
    </SecondaryButton>
  );
};

export default LoadingSecondaryButton;
