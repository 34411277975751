import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import LoadingSecondaryButton from 'components/Buttons/LoadingSecondaryButton';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import useParam from 'hooks/useParam';

import { useAuthUser } from 'utilities/AuthUser';

import api from 'api/app';

import {
  container,
  info,
  inputContainer,
  input,
  btn,
} from './ResetPassword.module.scss';
import {
  errorMessage,
  successMessage,
} from 'styles/components/form.module.scss';

import Hero from './Hero/Hero';

const ResetPassword = () => {
  const token = useParam('token');
  const { resetUser } = useAuthUser();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!token) {
    if (typeof window !== 'undefined') {
      navigate('/app/account');
    }
    return null;
  }

  const repeatVal = (passwordRepeat) =>
    passwordRepeat === getValues().password || 'Ο κωδικός δεν είναι ο ίδιος';

  const onSubmit = ({ password, passwordConfirmation }) => {
    setError(false);
    setSuccess(false);
    setLoading(true);
    api
      .post('/auth/reset-password', {
        token,
        password,
        passwordConfirmation,
      })
      .then((res) => {
        resetUser(res);
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Hero title="ΝΕΟΣ ΚΩΔΙΚΟΣ">
      <div className={container}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <p className={info}>Συμπλήρωσε τον νέο κωδικό χρήστη.</p>
          <div className={inputContainer}>
            <input
              {...register('password', {
                required: 'Το πεδίο Κωδικός είναι υποχρεωτικό',
                minLength: { value: 3, message: 'Πολύ μικρός κωδικός' },
                maxLength: { value: 100, message: 'Πολύ μεγάλος κωδικός' },
              })}
              type="password"
              placeholder="Κωδικός"
              aria-label="Κωδικός"
              className={input}
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
          </div>
          <div className={inputContainer}>
            <input
              {...register('passwordConfirmation', {
                required: 'Το πεδίο Επαλήθευση Κωδικού είναι υποχρεωτικό',
                validate: repeatVal,
              })}
              type="password"
              placeholder="Επαλήθευση Κωδικού"
              aria-label="Επαλήθευση Κωδικού"
              className={input}
            />
            {errors.passwordConfirmation && (
              <ErrorMessage>{errors.passwordConfirmation.message}</ErrorMessage>
            )}
          </div>
          <LoadingSecondaryButton
            buttonClass={btn}
            type="submit"
            loading={loading}
          >
            Συνέχεια
          </LoadingSecondaryButton>
        </form>
        {error && (
          <p className={errorMessage}>
            Η δημιουργία νέου κωδικού δεν ολοκληρώθηκε. Προσπάθησε ξανά!
          </p>
        )}
        {success && (
          <p className={successMessage}>
            Η δημιουργία νέου κωδικού κωδικού ήταν επιτυχής.
          </p>
        )}
      </div>
    </Hero>
  );
};

export default ResetPassword;
