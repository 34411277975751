import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import {
  row,
  rowInner,
  fullRow,
  inputContainer,
  label,
  input,
  select,
} from 'styles/components/form.module.scss';

const infoText = { fontWeight: 500 };

const AddressForm = ({ address, onSubmit, containerClass = false }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: address,
  });

  useEffect(() => {
    reset(address);
  }, [address, reset]);

  return (
    <form
      id="address-form"
      className={`${containerClass ? ' ' + containerClass : ''}`}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <h4 style={infoText}>Νέα Διεύθυνση</h4>
      <div className={row}>
        <div className={`${fullRow} ${inputContainer}`}>
          <label className={label} htmlFor="region">
            Περιοχή
          </label>
          <select className={select} {...register('region')}>
            <option value="Αττική">Αττική</option>
            <option value="Πελοπόννησος">Πελοπόννησος</option>
            <option value="Ιόνια Νησιά">Ιόνια Νησιά</option>
            <option value="Στερεά Ελλάδα">Στερεά Ελλάδα</option>
            <option value="Δυτική Ελλάδα">Δυτική Ελλάδα</option>
            <option value="Ήπειρος">Ήπειρος</option>
            <option value="Θεσσαλία">Θεσσαλία</option>
            <option value="Δυτική Μακεδονία">Δυτική Μακεδονία</option>
            <option value="Κεντρική Μακεδονία">Κεντρική Μακεδονία</option>
            <option value="Ανατολική Μακεδονία και Θράκη">
              Ανατολική Μακεδονία και Θράκη
            </option>
            <option value="Βόρειο Αιγαίο">Βόρειο Αιγαίο</option>
            <option value="Νότιο Αιγαίο">Νότιο Αιγαίο</option>
            <option value="Κρήτη">Κρήτη</option>
          </select>
        </div>
      </div>

      <div className={row}>
        <div className={`${fullRow} ${inputContainer}`}>
          <label className={label} htmlFor="city">
            Πόλη
          </label>
          <input
            className={input}
            id="city"
            {...register('city', {
              required: 'Το πεδίο Πόλη είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="Πόλη"
          />
          {errors.city && <ErrorMessage>{errors.city.message}</ErrorMessage>}
        </div>
      </div>

      <div className={row}>
        <div className={`${fullRow} ${inputContainer}`}>
          <label className={label} htmlFor="street">
            Οδός
          </label>
          <input
            className={input}
            id="street"
            {...register('street', {
              required: 'Το πεδίο Οδός είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="Οδός"
          />
          {errors.street && (
            <ErrorMessage>{errors.street.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className={row}>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="streetNumber">
            Αριθμός
          </label>
          <input
            className={input}
            {...register('streetNumber', {
              required: 'Το πεδίο Αριθμός είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="Αριθμός"
          />
          {errors.streetNumber && (
            <ErrorMessage>{errors.streetNumber.message}</ErrorMessage>
          )}
        </div>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="postalCode">
            Τ.Κ.
          </label>
          <input
            className={input}
            id="postalCode"
            {...register('postalCode', {
              required: 'Το πεδίο Τ.Κ. είναι υποχρεωτικό',
              pattern: {
                value: /\b\d{5}\b/,
                message: 'Λάθος μορφή Τ.Κ.',
              },
            })}
            type="text"
            aria-label="Τ.Κ."
          />
          {errors.postalCode && (
            <ErrorMessage>{errors.postalCode.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className={row}>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="nameAtBell">
            Κουδούνι
          </label>
          <input
            className={input}
            id="nameAtBell"
            {...register('nameAtBell')}
            type="text"
            aria-label="Όνομα στο κουδούνι"
          />
          {errors.nameAtBell && (
            <ErrorMessage>{errors.nameAtBell.message}</ErrorMessage>
          )}
        </div>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="floor">
            Όροφος
          </label>
          <input
            className={input}
            {...register('floor')}
            type="text"
            aria-label="Όροφος"
          />
          {errors.floor && <ErrorMessage>{errors.floor.message}</ErrorMessage>}
        </div>
      </div>
    </form>
  );
};

export default AddressForm;
