import React from 'react';
import { useForm } from 'react-hook-form';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import { container, inputContainer, label, input } from './Login.module.scss';

const Login = ({ onSubmit, actions }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <form
      className={`${container}`}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={inputContainer}>
        <label className={label} htmlFor="email">
          Email
        </label>
        <input
          {...register('email', {
            required: 'Το email είναι υποχρεωτικό',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Λάθος μορφή email',
            },
          })}
          type="email"
          aria-label="Email"
          className={input}
        />
        {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
      </div>
      <div className={inputContainer}>
        <label className={label} htmlFor="password">
          Κωδικός πρόσβασης
        </label>
        <input
          {...register('password', {
            required: 'Το password είναι υποχρεωτικό',
            minLength: { value: 3, message: 'Πολύ μικρό password' },
            maxLength: { value: 100, message: 'Πολύ μεγάλο password' },
          })}
          type="password"
          aria-label="Κωδικός"
          className={input}
        />
        {errors.password && (
          <ErrorMessage>{errors.password.message}</ErrorMessage>
        )}
      </div>
      {actions}
    </form>
  );
};

export default Login;
