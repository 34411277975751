import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import validateTaxId from 'utilities/taxIdValidator';

import {
  row,
  rowInner,
  fullRow,
  inputContainer,
  label,
  input,
  select,
} from 'styles/components/form.module.scss';

const infoText = { fontWeight: 500 };
const BusinessForm = ({ address, onSubmit, containerClass = false }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: address,
  });

  useEffect(() => {
    reset(address);
  }, [address, reset]);

  return (
    <form
      id="address-form"
      className={`${containerClass ? ' ' + containerClass : ''}`}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <h4 style={infoText}>Νέα Επιχείρηση</h4>
      <div className={row}>
        <div className={`${fullRow} ${inputContainer}`}>
          <label className={label} htmlFor="companyName">
            Επωνυμία
          </label>
          <input
            className={input}
            id="companyName"
            {...register('companyName', {
              required: 'Το πεδίο Επωνυμία επιχείρησης είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="Επωνυμία επιχείρησης"
          />
          {errors.companyName && (
            <ErrorMessage>{errors.companyName.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className={row}>
        <div className={`${fullRow} ${inputContainer}`}>
          <label className={label} htmlFor="profession">
            Επάγγελμα
          </label>
          <input
            className={input}
            id="profession"
            {...register('profession', {
              required: 'Το πεδίο Επάγγελμα είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="Επάγγελμα"
          />
          {errors.profession && (
            <ErrorMessage>{errors.profession.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className={row}>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="taxId">
            ΑΦΜ
          </label>
          <input
            className={input}
            id="taxId"
            {...register('taxId', {
              required: 'Το πεδίο ΑΦΜ είναι υποχρεωτικό',
              validate: (value) => (validateTaxId(value) ? true : 'Λάθος ΑΦΜ'),
            })}
            type="text"
            aria-label="ΑΦΜ"
          />
          {errors.taxId && <ErrorMessage>{errors.taxId.message}</ErrorMessage>}
        </div>

        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="taxOffice">
            ΔΟΥ
          </label>
          <input
            className={input}
            id="taxOffice"
            {...register('taxOffice', {
              required: 'Το πεδίο ΔΟΥ είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="ΔΟΥ"
          />
          {errors.taxOffice && (
            <ErrorMessage>{errors.taxOffice.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className={row}>
        <div className={`${fullRow} ${inputContainer}`}>
          <label className={label} htmlFor="companyRegion">
            Περιοχή
          </label>
          <select className={select} {...register('companyRegion')}>
            <option value="Αττική">Αττική</option>
            <option value="Πελοπόννησος">Πελοπόννησος</option>
            <option value="Ιόνια Νησιά">Ιόνια Νησιά</option>
            <option value="Στερεά Ελλάδα">Στερεά Ελλάδα</option>
            <option value="Δυτική Ελλάδα">Δυτική Ελλάδα</option>
            <option value="Ήπειρος">Ήπειρος</option>
            <option value="Θεσσαλία">Θεσσαλία</option>
            <option value="Δυτική Μακεδονία">Δυτική Μακεδονία</option>
            <option value="Κεντρική Μακεδονία">Κεντρική Μακεδονία</option>
            <option value="Ανατολική Μακεδονία και Θράκη">
              Ανατολική Μακεδονία και Θράκη
            </option>
            <option value="Βόρειο Αιγαίο">Βόρειο Αιγαίο</option>
            <option value="Νότιο Αιγαίο">Νότιο Αιγαίο</option>
            <option value="Κρήτη">Κρήτη</option>
          </select>
        </div>
      </div>

      <div className={row}>
        <div className={`${fullRow} ${inputContainer}`}>
          <label className={label} htmlFor="companyCity">
            Πόλη
          </label>
          <input
            className={input}
            id="companyCity"
            {...register('companyCity', {
              required: 'Το πεδίο Πόλη είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="Πόλη"
          />
          {errors.companyCity && (
            <ErrorMessage>{errors.companyCity.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className={row}>
        <div className={`${fullRow} ${inputContainer}`}>
          <label className={label} htmlFor="companyStreet">
            Οδός
          </label>
          <input
            className={input}
            id="companyStreet"
            {...register('companyStreet', {
              required: 'Το πεδίο Οδός είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="Οδός"
          />
          {errors.companyStreet && (
            <ErrorMessage>{errors.companyStreet.message}</ErrorMessage>
          )}
        </div>
      </div>

      <div className={row}>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="companyStreetNumber">
            Αριθμός
          </label>
          <input
            className={input}
            {...register('companyStreetNumber', {
              required: 'Το πεδίο Αριθμός είναι υποχρεωτικό',
            })}
            type="text"
            aria-label="Αριθμός"
          />
          {errors.companyStreetNumber && (
            <ErrorMessage>{errors.companyStreetNumber.message}</ErrorMessage>
          )}
        </div>
        <div className={`${rowInner} ${inputContainer}`}>
          <label className={label} htmlFor="companyPostalCode">
            Τ.Κ.
          </label>
          <input
            className={input}
            id="companyPostalCode"
            {...register('companyPostalCode', {
              required: 'Το πεδίο Τ.Κ. είναι υποχρεωτικό',
              pattern: {
                value: /\b\d{5}\b/,
                message: 'Λάθος μορφή Τ.Κ.',
              },
            })}
            type="text"
            aria-label="Τ.Κ."
          />
          {errors.companyPostalCode && (
            <ErrorMessage>{errors.companyPostalCode.message}</ErrorMessage>
          )}
        </div>
      </div>
    </form>
  );
};

export default BusinessForm;
